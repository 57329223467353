import API from './apiHelper'
import orderTransform from './taskOrderTransform'
import { fixBrand } from './taskOrderTransform'

export default {
  // ---------------------- V1 ENDPOINTS -----------------------

  getNbnPlanChangeOrders() {
    return API.get(`/v1/order?request_item_type=tc4.nbn.planchange`, {
      spinner: false,
      cacheSeconds: 60,
      async transform(response) {
        /*
          This endpoint gets an array of orders with nested request items. The request items
          contain pretty much all the information we need, with just one or two data points
          from the containing order.

          So we convert this nested structure to a flat list of request items, which
          is much easier for the UI to work with.
        */
        const requestItems = []
        response.data?.forEach(order => {
          order.request_items.forEach(item => {
            item.brand = fixBrand(item.brand || order.business_unit)
            item.sales_channel = order.sales_channel
            requestItems.push(item)
          })
        })
        return requestItems.sort((a, b) =>
          a.ts_created.localeCompare(b.ts_created)
        )
      }
    })
  },

  getNbnPlanChangeOrder(id, hideSpinner) {
    return API.get(`/v1/order/${id}`, {
      spinner: !hideSpinner,
      transform(data) {
        data.request_items.map(i => {
          // Make contacts consistent with the rest of the api...
          i.contacts.map(c => {
            c.contactType = c.type
            c.name = `${c.first_name} ${c.last_name}`
          })
          setRequestItemActivityInfo(i)
          transformRequestItemHistory(i)
          return i
        })
        return data
      }
    })
  },

  getDisconnectionOrder(id) {
    return API.get(`/v1/order?limit=100000`, {
      async transform(response) {
        const orders = response.data
        const order = orders.find(o => String(o.id) === String(id))

        if (order) {
          await loadRequestItems(order)
          transformRequestItems(order)
        }
        return order
      }
    })
  },

  getRequestItems() {
    return API.get(
      `/v1/request_item/tc4_disconnect?limit=100000&state=complete&state=pending&state=in progress`,
      {
        cacheSeconds: 60,
        spinner: false,
        transform(response) {
          return response.data.map(item => {
            item.brand = fixBrand(item.brand)
            return item
          })
        }
      }
    )
  },

  getRequestItem(id) {
    return API.get(`/v1/request_item/tc4_disconnect/${id}`)
  },

  getRequestItemNotes(id) {
    return API.get(`/v1/request_item/${id}/notes`, { spinner: false })
  },

  getRequestItemNoteTypes() {
    return [
      { label: 'General', value: 'General', icon: 'chat' },
      {
        label: 'Follow-up required',
        value: 'Follow-up Required',
        icon: 'update'
      },
      {
        label: 'Carrier update',
        value: 'Carrier Update',
        icon: 'captive_portal'
      },
      { label: 'Customer update', value: 'Customer Update', icon: '3p' }
    ]
  },

  postRequestItemNote(orderId, requestItemId, noteType, content) {
    return API.post(`/v1/request_item/${requestItemId}/notes`, {
      data: {
        content: content,
        note_type: noteType,
        request_item_id: requestItemId,
        sonic_order_id: orderId
      }
    })
  },

  getUiFlags() {
    return API.get(`/v1/ui_flags`, { spinner: false })
  },

  getApiSpec() {
    return API.get(`/v1/swagger`, { spinner: false })
  },

  updatePlanChangeCRD(requestItemId, date) {
    return API.post(
      `/v1/request_item/${requestItemId}/camunda_variables/tc4_planchange`,
      {
        spinner: false,
        data: {
          customer_requested_date: `${date}T00:00:00.000Z`
        }
      }
    )
  },

  // ---------------------- V0 ENDPOINTS -----------------------

  getOrders() {
    return API.get(`/v0/workflow/tc4.nbn.connect/summary`, {
      cacheSeconds: 120,
      spinner: false,
      transform(response) {
        let orders = response.data.map(o => orderTransform(o, true))
        orders.sort((a, b) => {
          if (a.createdDate && b.createdDate) {
            return b.createdDate?.localeCompare(a.createdDate) // Newest date first
          }
          if (a.createdDate && !b.createdDate) {
            return -1 // Place dates above empty dates
          }
          return 1
        })
        return orders
      }
    })
  },

  getOrder(id, hideSpinner) {
    return API.get(`/v0/workflow/instance/${id}`, {
      spinner: !hideSpinner,
      async transform(response) {
        var nbnComments
        try {
          nbnComments = await getNbnComments(id)
        } catch (e) {
          // If nbn comments fail, there's nothing much we can do other than show no comments
        }
        return orderTransform(response.data, null, nbnComments || [])
      }
    })
  },

  getNbnComments(id) {
    return getNbnComments(id)
  },

  editOrder(sonicRef, data) {
    return API.post(`/v0/order/${sonicRef}/edit`, { data })
  },

  customerRequestedDate(order, date) {
    return API.post(
      `/v0/workflow/instance/${order.processInstanceId}/customerRequestedDate`,
      {
        data: {
          value: date + 'T00:00:00.000Z',
          type: 'Date'
        }
      }
    )
  },

  postHistoryNote(processInstanceId, noteType, content) {
    return API.post(`/v0/instance/${processInstanceId}/note`, {
      data: {
        noteType, // See getHistoryNoteTypes(),
        content
      }
    })
  },

  getHistoryNoteTypes() {
    return [
      { value: 'note_follow_up', label: 'Follow up' },
      { value: 'note_customer_enquiry', label: 'Customer enquiry' },
      { value: 'note_carrier_enquiry', label: 'Carrier enquiry' },
      { value: 'note_order_update', label: 'Order update' }
    ]
  },

  cancelOrder(orderRef) {
    return API.post(`/v0/order/${orderRef}/withdraw`)
  },

  alignOrder(swoopOrderRef, nbnOrderRef) {
    return API.post(`/v0/order/${swoopOrderRef}/align/${nbnOrderRef}`)
  },

  replaceNbnOrder(processInstanceId) {
    return API.post(
      `/v0/process_instance/${processInstanceId}/cancel_inflight_product_order`
    )
  },

  addTracking(orderRef, tracking_id, serial) {
    return API.post(`/v0/order/${orderRef}/set_delivery_details`, {
      data: {
        tracking_id,
        serial
      }
    })
  }
}

// ---------------------- UTILITY FUNCTIONS -----------------------

async function loadRequestItems(order) {
  const itemRequests = [],
    notesRequests = []
  order.request_items.forEach(i =>
    itemRequests.push(
      API.get(`/v1/request_item/tc4_disconnect/${i.id}`, { spinner: false })
    )
  )
  order.request_items = await Promise.all(itemRequests)

  // Populate request item notes
  order.request_items.forEach(i =>
    notesRequests.push(
      API.get(`/v1/request_item/${i.id}/notes`, { spinner: false })
    )
  )
  const notes = await Promise.all(notesRequests)
  notes.forEach((note, idx) => {
    order.request_items[idx]._notes = note.data.sort((b, a) =>
      a.ts_created.localeCompare(b.ts_created)
    )
  })
}

function transformRequestItems(order) {
  order.request_items.forEach(i => {
    i.brand = fixBrand(i.brand)
    setRequestItemActivityInfo(i)
    transformRequestItemHistory(i)
  })
}

function setRequestItemActivityInfo(i) {
  if (i.workflow_activity) {
    // Fix inconsistent api data... again
    i.activity_instances = [i.workflow_activity]
    delete i.workflow_activity
  }

  if (i.activity_instances) {
    const steps = getSteps(i.activity_instances[0])
    const activity = steps[steps.length - 1]
    i._activityName = activity.name

    /*
          Join steps into a path
            (e.g."TC4 disconnection > Place disconnect order > Publish order cancelled event")
          but skip the first step ("TC4 disconnection"), as this
          will be the same for all disconnection orders .
        */
    i._activityPath = steps
      .filter((s, idx) => idx > 0)
      .map(s => s.name || s.activity_id)
      .join(' > ')
    i._activityId = activity.activity_id

    i._userTaskProcessInstanceId =
      activity.activity_type === 'userTask' &&
      activity.name !== 'Order Finished Processing'
        ? i.process_instance_id
        : null
  }
}

function getSteps(step, steps = []) {
  /*
    Recursively builds an array of workflow steps (activity_instances) from root to final child.
    We need to use this conversion on activity data from different V1 order endpoints, but unfortunately
    the API has inconsistent field naming between endpoints. Which is why each value is set from
    one of two field names, e.g.

    activity_id: step.activity_id || step.activityID
  */
  const top = !steps.length
  steps.push({
    id: step.id,
    activity_id: step.activity_id || step.activityID,
    name: step.name || step.activityName,
    activity_type: step.activity_type || step.activityType
  })
  if (step.children) {
    getSteps(step.children[0], steps)
  }
  if (step.childWorkflowActivities) {
    getSteps(step.childWorkflowActivities[0], steps)
  }
  if (top) {
    return steps
  }
}

function transformRequestItemHistory(i) {
  // Map request item history field names to V0 so that EventHistory component can be used...
  const history = i.history || []
  i.history = history
    .map(h => {
      return {
        content: h.content,
        eventType: h.event_type,
        id: h.id,
        createdDate: h.ts,
        loggedInUserId: h.user_id
      }
    })
    .sort((b, a) => a.createdDate.localeCompare(b.createdDate)) // Sort with newest event first
}

async function getNbnComments(id) {
  return await API.get(`/v0/process_instance/${id}/nbn_comments`, {
    silent: true,
    transform(response) {
      return response?.GetNbnComments200 || []
    }
  })
}
